<template>
  <div style="margin-top: 7vh">
    <v-card class="rounded-xl pa-10" flat>
      <div class="center-logo">
        <app-logo></app-logo>
      </div>
      <div class="d-flex justify-center">
        <strong>Thanks, Uploaded successfully!</strong>
      </div>
      <div class="d-flex justify-center mt-5">
        <v-icon color="success" x-large>mdi-check-circle</v-icon>
      </div>
      <div class="d-flex justify-center mt-8">
        <v-btn @click="backToHome" class="capitalize" rounded color="primary">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Back to home
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import AppLogo from "./Logo";
export default {
  components: {
    AppLogo,
  },
  name: "UploadCompleted",
  data() {
    return {};
  },
  methods: {
    backToHome() {
      window.location.href = "/dashboard";
      // this.$router.replace({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped></style>
