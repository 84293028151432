<template>
  <div>
    <router-link to="/">
      <v-img class="logo-size" :src="require('../assets/logo.png')"></v-img>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AppLogo",

  data() {
    return {};
  },
};
</script>

<style>
.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}
</style>
