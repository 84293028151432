<template>
  <div class="mt-6">
    <v-card
      :style="finished ? 'opacity: 1' : 'opacity: 0; position: absolute;'"
      class="pt-8 white rounded-xl"
    >
      <div class="center-logo">
        <app-logo></app-logo>
      </div>
      <v-toolbar
        class="font-weight-bold text-h5 mt-n5 d-flex justify-center"
        flat
      >
        Here's your recording
      </v-toolbar>
      <div :class="!final_step ? '' : 'showScreen'">
        <video
          id="screenVideo"
          style="width: 80%"
          height="auto"
          controls
          playsinline
        ></video>
      </div>
      <v-card-actions class="pa-10">
        <v-spacer></v-spacer>
        <v-btn
          @click="redoRecording"
          class="secondary black--text capitalize rounded-xl"
          large
          text
        >
          <v-icon>mdi-reload</v-icon>
          Redo
        </v-btn>
        <v-btn
          @click="finalStep"
          class="primary capitalize rounded-xl ml-6"
          large
          text
        >
          Proceed to final step
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <final-step v-if="final_step"></final-step>
  </div>
</template>

<script>
import AppLogo from "./Logo";
import FinalStep from "./FinalStep";
import RecordRTC from "recordrtc";
export default {
  components: {
    AppLogo,
    FinalStep,
  },
  props: {
    mic: {
      default: false,
      type: Boolean,
    },
  },
  name: "RecordScreen",
  data() {
    return {
      disabled: true,
      firstPage: true,
      startBtn: false,
      shareScreen: false,
      finished: false,
      final_step: false,
      showScreen: {
        opacity: 0,
        position: "absolute",
      },
      mediaOptions: {
        video: true,
        audio: true,
      },
      video: null,
      camera: null,
      recorder: null,
      webCam: null,
      timer: null,
      time: null,
      firefox: navigator.userAgent.includes("Firefox"),
    };
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    this.video = document.getElementById("screenVideo");
    // eslint-disable-next-line no-unused-vars
    let audioTrack;
    if (!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
      let error = "Your browser does NOT support the getDisplayMedia API.";
      document.querySelector("h1").innerHTML = error;
      document.getElementById("screenVideo").style.display = "none";
      throw new Error(error);
    }

    const invokeGetDisplayMedia = (success, error) => {
      if (navigator.mediaDevices.getDisplayMedia && this.$parent.toggleScreen) {
        navigator.mediaDevices
          .getDisplayMedia(this.mediaOptions)
          .then(success)
          .catch(error);
      }
      if (this.$parent.toggleCamera) {
        navigator.mediaDevices
          .getUserMedia(this.mediaOptions)
          .then(success)
          .catch(error);
      }
    };

    const stopRecordingCallback = () => {
      this.$parent.recordStarted = false;
      this.$parent.firstPage = false;
      this.$parent.shareScreen = true;
      this.$parent.disabled = false;
      this.$parent.record_btn = "start recording screen";
      this.video.muted = false;
      this.video.autoplay = false;
      this.video.controls = true;
      this.video.src = this.video.srcObject = null;
      if (this.$parent.toggleCamera) {
        this.webCam.getTracks().forEach(function (track) {
          track.stop();
        });
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      }
      this.video.src = URL.createObjectURL(this.recorder.getBlob());

      this.recorder.screen.stop();
      this.finished = true;
      clearInterval(this.timer);
    };

    const captureScreen = (callback) => {
      invokeGetDisplayMedia(
        (screen) => {
          addStreamStopListener(screen, () => {
            this.recorder.stopRecording(stopRecordingCallback);
          });
          callback(screen);
        },
        (error) => {
          console.error(error);
          window.location.reload();
        }
      );
    };

    const recordCamera = async () => {
      if (this.$parent.toggleCamera) {
        if (!this.$parent.toggleScreen) {
          this.$parent.recordStarted = true;
        }
        const handleSuccess = (stream) => {
          this.camera = document.getElementById("webCamera");
          this.camera.srcObject = stream;
          this.webCam = stream;
          this.camera.autoplay = true;
          if (this.$parent.toggleScreen) {
            this.camera.onloadedmetadata = async () => {
              await this.camera.requestPictureInPicture();
            };
          }
        };
        await navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(handleSuccess)
          .catch((e) => {
            throw e;
          });
      }
    };

    document.getElementById("camBtn").addEventListener("click", async () => {
      if (this.$parent.toggleScreen && this.$parent.toggleCamera) {
        await recordCamera();
      } else if (document.pictureInPictureElement) {
        this.webCam.getTracks().forEach(function (track) {
          track.stop();
        });
        document.exitPictureInPicture();
      }
    });

    document.getElementById("captureBtn").onclick = () => {
      if (this.$parent.toggleScreen || this.$parent.toggleCamera) {
        if (this.finished) {
          this.finished = false;
        }
        if (this.recorder) {
          this.recorder = null;
        }
        this.video.style.opacity = "0";
        this.disabled = true;
        const recordAudio = async (screen) => {
          const audioStream = await navigator.mediaDevices
            .getUserMedia({ audio: true })
            .catch((e) => {
              throw e;
            });
          [audioTrack] = audioStream.getAudioTracks();
          screen.addTrack(audioTrack);
        };
        captureScreen(async (screen) => {
          this.$parent.startBtn = false;
          this.$parent.firstPage = true;
          this.$parent.shareScreen = false;
          this.$parent.disabled = true;
          this.$parent.record_btn = "Stop Recording";
          this.timer = setInterval(() => {
            this.time += 1;
          }, 1000);
          this.recorder = RecordRTC(screen, {
            type: "video",
            audioBitsPerSecond: 128 * 1000,
            videoBitsPerSecond: 5 * 200000,
            mimeType: "video/webm",
          });
          this.video.muted = true;
          this.video.controls = false;

          if (this.mic) {
            if (
              (this.$parent.toggleCamera && !this.firefox) ||
              this.$parent.toggleScreen
            ) {
              await recordAudio(screen);
            }
          }

          if (this.$parent.toggleCamera && !this.$parent.toggleScreen) {
            await recordCamera();
          }
          this.video.srcObject = screen;

          this.recorder.startRecording();
          this.video.autoplay = true;
          this.video.style.opacity = "1";

          this.recorder.screen = screen;
        });
      }
    };

    const addStreamStopListener = (stream, callback) => {
      stream.addEventListener(
        "ended",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.addEventListener(
        "inactive",
        function () {
          callback();
          callback = function () {};
        },
        false
      );
      stream.getTracks().forEach(function (track) {
        track.addEventListener(
          "ended",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
        track.addEventListener(
          "inactive",
          function () {
            callback();
            callback = function () {};
          },
          false
        );
      });
    };
    this.$root.$on("stop-recording", () => {
      this.recorder.stopRecording(stopRecordingCallback);
    });
  },
  methods: {
    redoRecording() {
      this.video.pause();
      window.location.reload();
    },
    finalStep() {
      this.finished = false;
      this.final_step = true;
      this.video.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
.showScreen {
  opacity: 0;
  position: absolute;
  z-index: -999;
}
video {
  width: 30%;
  border-radius: 5px;
  border: 1px solid black;
}
</style>
