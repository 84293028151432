<template>
  <div>
    <v-card v-if="!uploading" class="pa-10 white rounded-xl" flat>
      <div class="center-logo">
        <app-logo></app-logo>
      </div>
      <v-toolbar
        class="font-weight-bold text-h5 mt-n5 d-flex justify-center capitalize"
        flat
      >
        Final Step, upload your recording
      </v-toolbar>
      <v-form lazy-validation ref="form" class="text-left">
        <div v-if="requestedLink">
          <label>Your Name <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            flat
            rounded
            background-color="secondary"
            placeholder="Enter your name"
            v-model="form.name"
            :rules="validate.required('Name')"
            type="text"
          ></v-text-field>
          <label>Email Address <span class="error--text">*</span></label>
          <v-text-field
            solo
            dense
            flat
            rounded
            background-color="secondary"
            placeholder="Enter your Email"
            v-model="form.email"
            :rules="validate.required('Email Address')"
            type="email"
          ></v-text-field>
        </div>
        <label>
          Recording Title
          <small>(This can be changed later)</small>
        </label>
        <v-text-field
          solo
          dense
          flat
          rounded
          background-color="secondary"
          placeholder="Enter the recording title"
          v-model="form.record_title"
          type="email"
        ></v-text-field>
        <label>Note <small>(This can be changed later)</small></label>
        <v-textarea
          solo
          dense
          flat
          rounded
          background-color="secondary"
          type="text"
          v-model="form.message"
          placeholder="Type your message"
        ></v-textarea>
      </v-form>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="capitalize grey--text" small text @click="goBack">
          <v-icon>mdi-keyboard-backspace</v-icon>
          Go back
        </v-btn>
        <v-btn
          class="capitalize primary px-8"
          rounded
          large
          :loading="loading"
          @click="$refs.form.validate() ? initiateUpload() : null"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="uploading">
      <upload-progress
        v-for="(file, index) in files"
        v-bind:key="file.file.uniqueIdentifier + index"
        :file="file.file"
        :status="file.status"
        :progress="file.progress"
        @cancel="cancelFile"
      ></upload-progress>
    </div>
  </div>
</template>

<script>
import AppLogo from "./Logo";
import FormValidation from "../utils/FormValidation";
import ResponseHelper from "../utils/ResponseHelper";
import UploadProgress from "./UploadProgress";
import { mapGetters, mapActions } from "vuex";
import Resumable from "resumablejs";
export default {
  components: {
    AppLogo,
    UploadProgress,
  },
  name: "FinalStep",
  data() {
    return {
      form: {},
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      uploading: false,
      loadCategories: false,
      chunks: [],
      files: [],
      r: false,
      total_chunks: 0,
      total_uploaded: 0,
      upload_message: "",
      video_name: "",
      loading: false,
    };
  },
  created() {
    if (this.$route.path.includes("/record")) {
      this.form.name = this.user.name ?? null;
      this.form.email = this.user.email ?? null;
      this.form.user_id = this.user.id ?? null;
    }

    this.$root.$on("uploaded-completed", () => {
      this.$parent.recorder.destroy();
      this.$parent.recorder = null;
    });
  },
  methods: {
    ...mapActions("recordings", ["user_can_upload"]),
    goBack() {
      this.$parent.finished = true;
      this.$parent.final_step = false;
      this.loading = false;
    },
    async initiateUpload() {
      this.loading = true;
      this.video_name =
        "video_" + Math.floor(Math.random() * 43210 * 12345) + ".mp4";

      this.form.video = new File(
        [this.$parent.recorder.getBlob()],
        this.video_name
      );
      this.form.duration = this.$parent.time;
      if (this.form.duration < 60) {
        this.form.duration = 60;
      }

      const support = () => {
        if (this.$route.query.support) {
          return { support: this.$route.query.support };
        }
        return null;
      };

      if (this.$route.path.includes("/request")) {
        this.form.requested_link = "Yes";
      }

      const baseURL =
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_PRODUCTION_URL
          : process.env.VUE_APP_STAGING_URL;
      try {
        this.uploading = true;
        // init resumablejs on mount
        this.r = new Resumable({
          target: baseURL + "/recordings/upload-video",
          query: {
            upload_token: "my_token",
            name: this.form.name,
            email: this.form.email,
            record_title: this.form.record_title,
            category: this.form.category,
            message: this.form.message,
            record_link: this.$route.params.personal_url,
            platform: window.navigator.userAgent,
            size: this.$parent.recorder.getBlob().size,
            duration: this.form.duration,
            label: this.form.label,
            ...support(),
            ...this.requestedLink,
            user_id: this.form.user_id,
          },
          simultaneousUploads: 1,
          maxChunkRetries: 3,
          testChunks: false,
          chunkSize: 1024 * 1024,
          forceChunkSize: true,
        });

        this.r.addFile(this.form.video);

        // Resumable.js isn't supported, fall back on a different method
        if (!this.r.support)
          return alert(
            "Your browser doesn't support chunked uploads. Get a better browser."
          );

        // set up event listeners to feed into vues reactivity
        this.r.on("fileAdded", (file) => {
          file.hasUploaded = false;
          // keep a list of files with some extra data that we can use as props
          this.files.push({
            file,
            status: "uploading",
            progress: 0,
          });
          this.r.upload();
        });
        this.r.on("fileSuccess", (file) => {
          this.findFile(file).status = "success";
        });
        this.r.on("fileError", (file) => {
          this.findFile(file).status = "error";
        });
        this.r.on("fileRetry", (file) => {
          this.findFile(file).status = "retrying";
        });
        this.r.on("fileProgress", (file) => {
          const localFile = this.findFile(file);
          // if we are doing multiple chunks we may get a lower progress number if one chunk response comes back early
          const progress = file.progress();
          if (progress > localFile.progress) localFile.progress = progress;
        });
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    findFile(file) {
      return (
        this.files.find(
          (item) =>
            item.file.uniqueIdentifier === file.uniqueIdentifier &&
            item.status !== "canceled"
        ) ?? {}
      );
    },
    cancelFile(file) {
      this.findFile(file).status = "canceled";
      file.cancel();
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("recordings", ["user_details"]),
    requestedLink() {
      if (this.$route.path.includes("/request")) {
        return { requested_link: "Yes" };
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
