<template>
  <v-container class="pa-0" fill-height fluid>
    <v-row
      :style="!recordStarted ? '' : 'position: absolute; opacity: 0'"
      justify="center"
    >
      <v-col v-if="!startBtn && firstPage" cols="12" md="6">
        <v-card class="rounded-xl white pa-10" flat>
          <div class="center-logo">
            <app-logo></app-logo>
          </div>
          <div><strong>Send A Recording</strong></div>
          <small v-if="$route.query.support" class="grey--text">
            Your screen recording will be
            {{ !$route.query.support ? "submitted through" : "sent to" }}
            <span class="primary--text">
              {{ $route.query.support || "public form" }}
            </span>
          </small>
          <div class="mt-3">
            <v-row justify="center" class="ml-n7">
              <v-switch
                :disabled="disabled"
                flat
                inset
                v-model="toggleMic"
                label="Audio"
              ></v-switch>
            </v-row>
            <v-row justify="center" class="mt-n6 ml-n5">
              <v-switch
                :disabled="
                  toggleCamera ||
                  (toggleScreen && record_btn === 'Stop Recording')
                "
                flat
                inset
                v-model="toggleScreen"
                label="Screen"
              ></v-switch>
            </v-row>
            <v-row justify="center" class="mt-n6">
              <v-switch
                :disabled="
                  (this.toggleScreen && firefox) ||
                  (record_btn !== 'Stop Recording' && this.toggleScreen)
                "
                flat
                inset
                v-model="toggleCamera"
                @change="clickCamBtn"
              >
                <template v-slot:label>
                  <span>Camera </span>
                  <small
                    style="position: absolute; min-width: 200px; left: 60px"
                    v-if="toggleScreen && firefox"
                  >
                    (Not Supported on Firefox)
                  </small>
                </template>
              </v-switch>
            </v-row>
          </div>
          <div class="d-flex justify-center mt-4">
            <v-btn
              :id="record_btn !== 'Stop Recording' ? 'captureBtn' : ''"
              @click="
                record_btn !== 'Stop Recording'
                  ? startRecording()
                  : stopRecording()
              "
              class="primary rounded-xl capitalize px-10"
              large
              :disabled="!toggleScreen && !toggleCamera"
            >
              <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
                mdi-square
              </v-icon>
              {{ record_btn }}
            </v-btn>
          </div>
          <div class="mt-10">
            <strong class="capitalize error--text">protect your privacy</strong>
            <small class="d-block grey--text">
              Before you start recording, you'll be prompted which screen or
              window you would like to record. You can preview your recording on
              this page before you decide to upload it.
            </small>
          </div>
          <v-row class="mt-3">
            <v-card-text
              @click="goBack"
              class="text-center grey--text pa-4 pointer"
              style="visibility: hidden"
            >
              <v-icon>mdi-keyboard-backspace</v-icon>
              Go back
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        v-if="startBtn"
        cols="12"
        md="6"
        style="position: absolute; top: 10px"
      >
        <v-card class="white py-4 px-7 rounded-xl">
          <div class="center-logo">
            <app-logo></app-logo>
          </div>
          <div class="mt-4">
            <strong class="capitalize text-h5">
              Recording will start once you allow access to your screen...
            </strong>
            <small class="d-block grey--text mt-6">
              Please follow your browser instructions
            </small>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="!shareScreen ? 'showScreen' : 'hideScreen'"
      >
        <video-js :mic="toggleMic"></video-js>
      </v-col>
    </v-row>
    <v-row
      class="mt-4"
      justify="center"
      :style="
        !toggleScreen && recordStarted && toggleCamera
          ? ''
          : 'opacity: 0; position: absolute; z-index: -9999'
      "
    >
      <v-col cols="12">
        <v-btn
          @click="stopRecording"
          class="primary rounded-xl capitalize px-10 float-right mr-16"
          large
        >
          <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
            mdi-square
          </v-icon>
          {{ record_btn }}
        </v-btn>
      </v-col>
      <v-col cols="10">
        <div>
          <video
            :width="screenClass ? '' : '100%'"
            height="500px"
            playsinline
            id="webCamera"
            :style="toggleScreen ? screenClass : ''"
            controls
          ></video>
        </div>
      </v-col>
    </v-row>
    <button style="visibility: hidden" id="camBtn">Button</button>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo";
import VideoJs from "../../components/VideoJs";
export default {
  components: {
    AppLogo,
    VideoJs,
  },
  name: "RecordScreen",
  data() {
    return {
      toggleMic: true,
      toggleScreen: false,
      toggleCamera: false,
      firstPage: true,
      startBtn: false,
      shareScreen: false,
      finished: false,
      showScreen: {
        opacity: 0,
        position: "absolute",
      },
      record_btn: "start recording",
      disabled: false,
      screenClass: "pointer-events: none; opacity: 0; position: absolute",
      recordStarted: false,
      firefox: navigator.userAgent.includes("Firefox"),
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    startRecording() {
      this.startBtn = true;
      this.firstPage = false;
      this.shareScreen = true;
    },
    stopRecording() {
      this.$root.$emit("stop-recording");
    },
    clickCamBtn() {
      document.getElementById("camBtn").click();
    },
  },
};
</script>

<style lang="scss" scoped>
.showScreen {
  opacity: 0;
  position: absolute;
  z-index: -999;
}
.hideScreen {
  opacity: 1;
  z-index: 1;
}
.blink {
  animation: fadeinout 1.5s infinite;
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
